
// A
export { ReactComponent as AlarmClock } from './icons/alarm-clock-check.svg';
export { ReactComponent as AlertTriangle } from './icons/alert-triangle.svg';
export { ReactComponent as Archive } from './icons/archive.svg';
export { ReactComponent as Announcement02 } from './icons/announcement-02.svg';
export { ReactComponent as ArrowNarrowDown } from './icons/arrow-narrow-down.svg';
export { ReactComponent as ArrowNarrowLeft } from './icons/arrow-narrow-left.svg';
export { ReactComponent as ArrowNarrowRight } from './icons/arrow-narrow-right.svg';
export { ReactComponent as ArrowNarrowUp } from './icons/arrow-narrow-up.svg';
export { ReactComponent as ArrowLeft } from './icons/arrow-left.svg';
export { ReactComponent as ArrowRight } from './icons/arrow-right.svg';
export { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
export { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
export { ReactComponent as ArrowSquareUpRight } from './icons/arrow-square-up-right.svg';


// B
export { ReactComponent as Building02 } from './icons/building-02.svg';
export { ReactComponent as BookClosed } from './icons/book-closed.svg';
export { ReactComponent as Box01 } from './icons/box.svg';
export { ReactComponent as BarChart08 } from './icons/bar-chart-08.svg';
export { ReactComponent as Briefcase01 } from './icons/briefcase-01.svg';

// C
export { ReactComponent as Camera01 } from './icons/camera-01.svg';
export { ReactComponent as CloudSun02 } from './icons/cloud-sun-02.svg';
export { ReactComponent as ClipboardCheck } from './icons/clipboard-check.svg';
export { ReactComponent as Check } from './icons/check.svg';
export { ReactComponent as CheckDone01 } from './icons/check-done-01.svg';
export { ReactComponent as CheckDone02 } from './icons/check-done-02.svg';
export { ReactComponent as CoinsSwap01 } from './icons/coins-swap-01.svg';
export { ReactComponent as CoinsSwap02 } from './icons/coins-swap-02.svg';
export { ReactComponent as CoinsHand } from './icons/coins-hand.svg';
export { ReactComponent as CoinsStacked02 } from './icons/coins-stacked-02.svg';
export { ReactComponent as CoinsStacked01 } from './icons/coins-stacked-01.svg';
export { ReactComponent as Copy01 } from './icons/copy-01.svg';
export { ReactComponent as Copy06 } from './icons/copy-06.svg';
export { ReactComponent as Copy07 } from './icons/copy-07.svg';
export { ReactComponent as Calendar } from './icons/calendar.svg';
export { ReactComponent as CalendarCheck02 } from './icons/calendar-check-02.svg';
export { ReactComponent as ClockCheck } from './icons/clock-check.svg';
export { ReactComponent as ChevronDown } from './icons/chevron-down.svg';
export { ReactComponent as ChevronUp } from './icons/chevron-up.svg';
export { ReactComponent as ChevronRight } from './icons/chevron-right.svg';
export { ReactComponent as Clapperboard } from './icons/clapperboard.svg';
export { ReactComponent as CheckSquare } from './icons/check-square.svg';
export { ReactComponent as CheckSquareBroken } from './icons/check-square-broken.svg';
export { ReactComponent as Cube } from './icons/cube-01.svg';
export { ReactComponent as Cube03 } from './icons/cube-03.svg';
export { ReactComponent as CreditCard } from './icons/credit-card-02.svg';


// D
export const DE = require('./icons/DE.svg').default;
export { ReactComponent as DotsVertical } from './icons/dots-vertical.svg';
export { ReactComponent as DownloadCloud01 } from './icons/download-cloud-01.svg';
export { ReactComponent as Download01 } from './icons/download-01.svg';
export { ReactComponent as Delete } from './icons/delete.svg';


// E
export const ES = require('./icons/ES.svg').default;
export { ReactComponent as Edit05 } from './icons/edit-05.svg';
export { ReactComponent as Eye } from './icons/eye.svg';

// F
export const FR = require('./icons/FR.svg').default;
export { ReactComponent as File06 } from './icons/file-06.svg';
export { ReactComponent as FileCheck01 } from './icons/file-check-01.svg';

// G
export const GB = require('./icons/GB.svg').default;
export { ReactComponent as Glasses02 } from './icons/glasses-02.svg';
export { ReactComponent as Grid01 } from './icons/grid-01.svg';

// H
export { ReactComponent as HomeLine } from './icons/home-line.svg';
export { ReactComponent as Home02 } from './icons/home-02.svg';

// I
export { ReactComponent as InfoCircle } from './icons/info-circle.svg';
export { ReactComponent as ImageX } from './icons/image-x.svg';
export const IT = require('./icons/IT.svg').default;

// J

// K

// L
export { ReactComponent as LifeBuoy01 } from './icons/life-buoy-01.svg';
export { ReactComponent as LogOut } from './icons/log-out-01.svg';
export { ReactComponent as ListIcon } from './icons/list.svg';
export { ReactComponent as Lock01 } from './icons/lock-01.svg';
export { ReactComponent as LockUnlocked01 } from './icons/lock-unlocked-01.svg';
export { ReactComponent as LineChartUp02 } from './icons/line-chart-up-02.svg';
export { ReactComponent as Lightbulb05 } from './icons/lightbulb-05.svg';

// M
export { ReactComponent as MarkerPin01 } from './icons/marker-pin-01.svg';
export { ReactComponent as MagicWand01 } from './icons/magic-wand-01.svg';
export { ReactComponent as Menu02 } from './icons/menu-02.svg';
export { ReactComponent as Minus } from './icons/minus.svg';

// N

// O

// P
export { ReactComponent as ParagraphWrap } from './icons/paragraph-wrap.svg';
export { ReactComponent as Passcode } from './icons/passcode.svg';
export { ReactComponent as PieChart03 } from './icons/pie-chart-03.svg';
export { ReactComponent as Plus } from './icons/plus.svg';
export { ReactComponent as PlusCircle } from './icons/plus-circle.svg';
export { ReactComponent as Play } from './icons/play.svg';
export { ReactComponent as Printer } from './icons/printer.svg';
export { ReactComponent as PackageCheck } from './icons/package-check.svg';

// Q

// R
export const RO = require('./icons/RO.svg').default;
export { ReactComponent as ReceiptCheck } from './icons/receipt-check.svg';

// S
export { ReactComponent as Settings01 } from './icons/settings-01.svg';
export { ReactComponent as SwitchHorizontal01 } from './icons/switch-horizontal-01.svg';
export { ReactComponent as ShoppingCart01 } from './icons/shopping-cart-01.svg';
export { ReactComponent as Save01 } from './icons/save-01.svg';
export { ReactComponent as SearchSm } from './icons/search-sm.svg';
export { ReactComponent as StickerSquare } from './icons/sticker-square.svg';
export { ReactComponent as Shuffle01 } from './icons/shuffle-01.svg';


// T
export { ReactComponent as Trash01 } from './icons/trash-01.svg';
export { ReactComponent as Trash03 } from './icons/trash-03.svg';
export { ReactComponent as Truck01 } from './icons/truck-01.svg';
export { ReactComponent as Truck02 } from './icons/truck-02.svg';
export { ReactComponent as Tag01 } from './icons/tag-01.svg';
export { ReactComponent as Tag03 } from './icons/tag-03.svg';

export { ReactComponent as Tag } from './icons/tag-01.svg';
// U
export { ReactComponent as Users01 } from './icons/users-01.svg';
export { ReactComponent as User01 } from './icons/user-01.svg';
// V
// W
export { ReactComponent as Wifi } from './icons/wifi.svg';
export { ReactComponent as WifiOff } from './icons/wifi-off.svg';

// X
export { ReactComponent as X } from './icons/x.svg';

// Y

// // Z
export { ReactComponent as ZoomIn } from './icons/zoom-in.svg';
export { ReactComponent as ZoomOut } from './icons/zoom-out.svg';

